/* stylelint-disable-next-line selector-max-universal */
* {
  /* stylelint-disable-next-line declaration-no-important */
  -moz-osx-font-smoothing: unset !important;
  /* stylelint-disable-next-line declaration-no-important */
  -webkit-font-smoothing: unset !important;
}

.mat-icon {
  /* stylelint-disable-next-line declaration-no-important */
  -moz-osx-font-smoothing: grayscale !important;
  /* stylelint-disable-next-line declaration-no-important */
  -webkit-font-smoothing: antialiased !important;
}

.mdc-circular-progress circle {
  stroke: var(--procure-color-white);
}

.mat-mdc-table.mdc-data-table__table .mdc-data-table__header-row {
  height: 3rem;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
  display: none;
}

.mat-mdc-menu-item .mdc-list-item__primary-text {
  width: 100%;
}

.mat-mdc-menu-item .mat-icon {
  flex-shrink: 0;
}

.mat-button-toggle-ripple {
  display: none;
}

.mdc-slider__value-indicator-text {
  white-space: nowrap;
}

.mat-expansion-panel.mat-expansion-panel-spacing {
  overflow: visible;

  .mat-expansion-panel-content.ng-animating {
    overflow: hidden;
  }
}

.mdc-dialog--open .mat-mdc-dialog-surface {
  border-radius: var(--procure-dialog-border-radius);
}

.mat-mdc-tab-label-container {
  /* stylelint-disable-next-line declaration-no-important */
  border-bottom: 0 !important;
}

.mat-mdc-form-field-infix {
  display: block;
  align-content: center;
  overflow: hidden;
}

.mat-mdc-radio-button .mdc-radio__background {
  /* stylelint-disable-next-line declaration-no-important */
  width: 1.25rem !important;
  /* stylelint-disable-next-line declaration-no-important */
  height: 1.25rem !important;
}

.mdc-slider__thumb {
  z-index: 1;

  .mdc-slider__value-indicator {
    transform-origin: center;
  }

  .mdc-slider__value-indicator-container {
    top: 44px;
    bottom: auto;
  }
}

.mat-expansion-panel .mat-expansion-panel-header {
  border-radius: 0;
}

.mat-button-toggle .mat-button-toggle-label-content {
  display: block;
}
