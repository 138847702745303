// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #ff000f
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$_palettes: (
  primary: (
    0: #000000,
    10: #410001,
    20: #690002,
    25: #7e0003,
    30: #930004,
    35: #a90006,
    40: #c00008,
    50: #ef000d,
    60: #ff5545,
    70: #ff8a7a,
    80: #ffb4a9,
    90: #ffdad5,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #2c1512,
    20: #442926,
    25: #513430,
    30: #5d3f3b,
    35: #6a4b46,
    40: #775652,
    50: #926f6a,
    60: #ae8882,
    70: #caa29c,
    80: #e7bdb7,
    90: #ffdad5,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #251a00,
    20: #3e2e04,
    25: #4a390e,
    30: #574419,
    35: #635023,
    40: #705c2e,
    50: #8b7444,
    60: #a68e5b,
    70: #c2a873,
    80: #dfc38c,
    90: #fcdfa6,
    95: #ffefd4,
    98: #fff8f2,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #201a19,
    20: #362f2e,
    25: #413a38,
    30: #4d4544,
    35: #59504f,
    40: #655c5b,
    50: #7f7573,
    60: #998e8d,
    70: #b4a9a7,
    80: #d0c4c2,
    90: #ede0de,
    95: #fbeeec,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
    4: #120d0c,
    6: #181211,
    12: #251e1d,
    17: #2f2827,
    22: #3b3332,
    24: #3f3736,
    87: #e4d7d5,
    92: #f3e5e3,
    94: #f8ebe9,
    96: #fef1ef,
  ),
  neutral-variant: (
    0: #000000,
    10: #251917,
    20: #3b2d2b,
    25: #473836,
    30: #534341,
    35: #5f4f4c,
    40: #6b5a58,
    50: #857370,
    60: #a08c89,
    70: #bba7a3,
    80: #d8c2be,
    90: #f5ddda,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

/* stylelint-disable-next-line scss/dollar-variable-pattern */
$_font-family-sans-serif: "ABBvoice", "Arial", sans-serif;
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$_primary-font: $_font-family-sans-serif;

/* stylelint-disable-next-line scss/dollar-variable-pattern */
$_typography: (
  plain-family: $_primary-font,
  brand-family: $_primary-font,
);

/* stylelint-disable-next-line scss/dollar-variable-pattern */
$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);

/* stylelint-disable-next-line scss/dollar-variable-pattern */
$_primary: map.merge(map.get($_palettes, primary), $_rest);
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
  typography: $_typography,
  density: (
    scale: -4
  )
));