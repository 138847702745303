.prevent-line-breaks {
  white-space: nowrap;
}

h1,
h2 {
  font-weight: 400;
}

.text-link {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.undo-link {
  margin-top: calc(var(--procure-vertical-spacing) * -1);
  margin-bottom: var(--procure-vertical-spacing);
  font-size: 0.75rem;
  line-height: 2;
  color: var(--procure-brand-accent);
}

.message__content p {
  margin: 0;
}
