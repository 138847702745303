@use "./variables";
@use "sass:map";


@mixin container() {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--procure-horizontal-spacing);
  padding-left: var(--procure-horizontal-spacing);

  @media (min-width: variables.$screen-sm-min) {
    width: 750px;
  }

  @media (min-width: variables.$screen-md-min) {
    width: 970px;
  }

  @media (min-width: variables.$screen-lg-min) {
    width: 1270px;
  }
}

@mixin card {
  border-radius: var(--procure-border-radius);
  background-color: var(--procure-card-background-color);
  box-shadow: var(--procure-box-shadow);
}

@function breakpoint($breakpoint) {
  @if map.has-key(variables.$breakpoints, $breakpoint) {
    @return map.get(variables.$breakpoints, $breakpoint);
  } @else {
    @warn "No breakpoint called "#{$breakpoint}" was found.Possible values include: #{map-keys(variables.$breakpoints)}";
    @return null;
  }
}

// The at-least mixin acts as a min-width media query for the given breakpoint.
@mixin at-least($breakpoint) {
  $width: breakpoint($breakpoint);

  @if $width {
    @media (min-width: $width) {
      @content;
    }
  }
}

@mixin up-to($breakpoint) {
  $width: breakpoint($breakpoint) * 0.999;

  @if $width {
    @media (max-width: $width) {
      @content;
    }
  }
}

@mixin cursor {
  display: block;
  position: relative;
  font-weight: 700;

  &::before {
    display: block;
    width: 100%;
    margin-bottom: 0.25rem;
    content: "—";
    color: var(--procure-brand-accent);
    font-size: 2rem;
    line-height: 0.5rem;
  }
}

@mixin line-clamp($max-lines: 3) {
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: #{$max-lines};
  -webkit-box-orient: vertical;
  line-clamp: #{$max-lines};
}

@mixin table-columns-widths($column-widths, $column-relative-widths) {
  @each $column, $width in $column-widths {
    &--#{$column} {
      min-width: $width;
    }
  }

  @each $column, $width in $column-relative-widths {
    &--#{$column} {
      width: $width;
    }
  }
}

@mixin status() {
  &__status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__status-icon {
    width: 1.5em;
    height: 1.5em;
    border-radius: var(--procure-border-radius);
    background-color: var(--procure-brand-grey);

    &--grey,
    &--Grey {
      background-color: var(--procure-status-grey);
    }

    &--blue,
    &--Blue {
      background-color: var(--procure-status-blue);
    }

    &--green,
    &--Green {
      background-color: var(--procure-status-green);
    }

    &--yellow,
    &--Yellow {
      background-color: var(--procure-status-yellow);
    }

    &--red,
    &--Red {
      background-color: var(--procure-status-red);
    }

    &--orange,
    &--Orange {
      background-color: var(--procure-status-orange);
    }

    &--white,
    &--White {
      background-color: var(--procure-status-white);
    }

    &--black,
    &--Black {
      background-color: var(--procure-color-black);
    }
  }
}

@mixin cell-heights($max) {
  @for $i from 1 through $max {
    &--height-#{$i} {
      min-height: calc(2.5rem * #{$i});
    }
  }

  &--height-0 {
    min-height: 2.5rem;
  }
} 

@mixin truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin info-cell($dark: false) {
  &__info {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: help;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;

    &::before {
      z-index: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 2rem;
      height: 2rem;
      content: "";

      @if $dark {
        background-image: linear-gradient(
          to top left,
          var(--procure-color-light-grey) 50%,
          transparent 50%
        );
      } @else {
        background-image: linear-gradient(
          to top left,
          var(--procure-color-grey) 50%,
          transparent 50%
        );
      }
    }
  }

  &__info-icon {
    z-index: 1;
    position: relative;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;

    @if $dark {
      color: var(--procure-color-dark-grey);
    } @else {
      color: var(--procure-brand-grey);
    }
  }
}

@mixin last-cell-border($root, $max) {
  @for $i from 2 through $max {
    #{$root}--#{$i} & {
      &:nth-child(#{$i}n) {
        border-right: 0;
      }
    }
  }
}

@mixin level-padding($root, $max, $n: 1) {
  @for $i from 1 through $max {
    #{$root}__item--level-#{$i} & {
        padding-left: calc(1rem * #{$n} * #{$i});
    }
  }
}

@mixin level-margin($root, $max, $n: 1) {
  @for $i from 1 through $max {
    #{$root}__item--level-#{$i} & {
        margin-left: calc(1rem + 1rem * #{$n} * #{$i});
    }
  }
}