.ck-editor__editable {
  min-height: 250px;
  max-height: 500px;
}

.ck-content {
  line-height: 1.25;

  //  stylelint-disable selector-max-type
  table td,
  table th {
    border: 1px solid var(--procure-border-color);
  }
  //  stylelint-enable selector-max-type
}

.ck-read-only {
  color: var(--procure-brand-grey);
}

.ck-content--responsive {
  hyphens: auto;
  word-break: break-word;

  img {
    max-width: 100%;
    height: auto;
  }
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 1005;
}
