@use "../base/variables";

input,
.mat-mdc-form-field {
  font-family: variables.$font-family-sans-serif;
}

.mat-mdc-form-field {
  width: 100%;
  margin-bottom: var(--procure-vertical-spacing);

  &.ng-invalid.ng-touched {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      /* stylelint-disable-next-line declaration-no-important */
      border-color: var(--procure-brand-error) !important;
    }

    /* stylelint-disable-next-line selector-max-specificity */
    .mdc-notched-outline__notch
      .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
      color: var(--procure-brand-error);
    }
  }
}

.input-required {
  height: 1rem;
  color: var(--procure-brand-error);
  font-size: 2rem;
  line-height: 0.8;
}

.mat-mdc-form-field-required-marker {
  display: none;
}

.form-row-link {
  margin-top: calc(var(--procure-vertical-spacing) * -1);
  margin-bottom: var(--procure-vertical-spacing);
  font-size: 0.75rem;
  line-height: 2;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: var(--procure-color-grey) !important;
}

.mdc-notched-outline__notch {
  /* stylelint-disable-next-line declaration-no-important */
  width: fit-content !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-top: 1px solid #d9d9d9 !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-right: 0 !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-left: 0 !important;

  .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
    margin-top: -2px;
    padding: 0 0.5rem;
    background-image: linear-gradient(
      to bottom,
      var(--procure-card-background-color) 50%,
      color-mix(in sRGB, var(--procure-brand-warning), var(--procure-color-white) 95%) 50%
    );
    color: var(--procure-brand-grey);
    font-size: 1rem;
    letter-spacing: 0.05em;
  }
}

.mdc-text-field--focused {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    /* stylelint-disable-next-line declaration-no-important */
    border-width: 1px !important;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch {
    /* stylelint-disable-next-line declaration-no-important */
    border-right: 0 !important;
  }

  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch {
    /* stylelint-disable-next-line declaration-no-important */
    border-left: 0 !important;
  }
}

.mat-mdc-radio-button {
  .mdc-form-field {
    display: flex;
    width: 100%;

    .mdc-radio {
      padding: 0;

      .mdc-radio__native-control {
        top: 0;
        right: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.mat-mdc-form-field-infix {
  padding-right: 5px;
  padding-left: 5px;
}

.mdc-text-field--outlined .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-size: 1rem;
}

.mat-form-field-disabled {
  .mdc-notched-outline__leading {
    border-style: dashed none dashed dashed;
  }

  .mdc-notched-outline__notch {
    /* stylelint-disable-next-line declaration-no-important */
    border-top-style: dashed !important;
    border-bottom-style: dashed;
  }

  .mdc-notched-outline__trailing {
    border-style: dashed dashed dashed none;
  }
}

.ng-select .ng-select-container .ng-value {
  font-size: 1rem;
}

.ng-select .ng-arrow-wrapper {
  line-height: 1.3;
}

.mdc-text-field {
  border-radius: var(--procure-border-radius);
  background-color: var(--procure-control-background-color);

  &:not(.mdc-text-field--disabled) .mdc-text-field__input.mat-mdc-input-element::placeholder {
    color: var(--procure-color-grey);
  }
}

.is-highlighted {
  .mdc-text-field {
    background-color: color-mix(in sRGB, var(--procure-control-prefilled), transparent 80%);
  }

  .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
    background-image: linear-gradient(
      to bottom,
      var(--procure-card-background-color) 50%,
      color-mix(in sRGB, var(--procure-control-prefilled), var(--procure-color-white) 80%) 50%
    );
  }
}

.is-bold {
  .mdc-text-field {
    font-weight: 700;
  }
}
