@use "../base/mixins";

$shepherd-font-size: 0.875rem !default;
$shepherd-font-weight: 400 !default;
$shepherd-line-height: 1.25 !default;
$shepherd-border-radius: 999px !default;

.shepherd-has-title .shepherd-content .shepherd-header {
  padding: var(--procure-vertical-spacing) var(--procure-horizontal-spacing);
  border-bottom: 1px solid var(--procure-border-color);
  background-color: var(--procure-color-white);
}

.shepherd-title {
  @include mixins.cursor;

  font-weight: 700;
}

.shepherd-button-stroked + .shepherd-button-flat {
  margin-left: auto;
}

.shepherd-text {
  padding: var(--procure-vertical-spacing) var(--procure-horizontal-spacing);
}

.shepherd-footer {
  padding: var(--procure-vertical-spacing) var(--procure-horizontal-spacing);
  border-top: 1px solid var(--procure-border-color);
}

.shepherd-button {
  &.shepherd-button-flat,
  &.shepherd-button-stroked {
    padding: calc(0.5em - 1px) calc(1.5em - 1px);
    border-radius: $shepherd-border-radius;
    font-size: $shepherd-font-size;
    font-weight: $shepherd-font-weight;
    line-height: $shepherd-line-height;
    text-align: center;
    text-decoration: none;
  }

  &.shepherd-button-flat {
    background-color: var(--procure-brand-grey);
    color: var(--procure-color-white);
  }

  &.shepherd-button-primary {
    background-color: var(--procure-brand-accent);
    color: var(--procure-color-white);
  }

  &.shepherd-button-stroked {
    border: 1px solid currentColor;
    background-color: transparent;
    color: var(--procure-brand-grey);
    line-height: $shepherd-line-height;

    &:hover {
      background-color: transparent;
      color: var(--procure-brand-grey);
    }
  }
}

.shepherd-element {
  box-shadow: none;
  filter: drop-shadow(0 1px 4px rgba(0 0 0 / 20%));
}
