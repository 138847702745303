@use "../base/mixins";

.cdk-overlay-container {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 1006 !important;
}

.cdk-overlay-pane {
  max-width: calc(100% - #{calc(var(--procure-horizontal-spacing) * 2)});
  margin: var(--procure-vertical-spacing) var(--procure-horizontal-spacing);
}

.dialog__header {
  padding: var(--procure-vertical-spacing) var(--procure-horizontal-spacing);
  border-bottom: 1px solid var(--procure-border-color);
}

.mat-mdc-dialog-container.mdc-dialog {
  width: 100%;
  max-width: 100%;
}

.mdc-dialog .mat-mdc-dialog-content.mdc-dialog__content {
  margin-bottom: 0;
  padding: var(--procure-vertical-spacing) var(--procure-horizontal-spacing) 0;
  color: var(--procure-color-black);
}

.dialog__title {
  @include mixins.cursor;

  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  // stylelint-disable-next-line declaration-no-important
  margin-top: 0 !important;
  margin-bottom: 0;
  overflow: hidden;
}

.dialog__footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--procure-vertical-spacing) var(--procure-horizontal-spacing);
  gap: 0.75rem;
  border-top: 1px solid var(--procure-border-color);

  @include mixins.up-to(l) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  margin: 0;
}
