//  To customize drop zone, class has to be defined globally
//  See: https:// github.com/georgipeltekov/ngx-file-drop/issues/76
.input-file__table {
  width: 100%;
}

.input-file__actions {
  display: flex;
  padding: 1.5rem 0;

  .collapp-button {
    margin: 0 0.5rem;
  }

  .collapp-button:first-child {
    margin-left: -0.5rem;
  }
}
