@font-face {
  font-family: ABBvoice;
  font-weight: 300;
  src:
    url("/assets/fonts/ABBvoice_W_Lt.eot") format("eot"),
    url("/assets/fonts/ABBvoice_W_Lt.woff2") format("woff2"),
    url("/assets/fonts/ABBvoice_W_Lt.woff") format("woff");
}

@font-face {
  font-family: ABBvoice;
  font-weight: 400;
  src:
    url("/assets/fonts/ABBvoice_W_Rg.eot") format("eot"),
    url("/assets/fonts/ABBvoice_W_Rg.woff2") format("woff2"),
    url("/assets/fonts/ABBvoice_W_Rg.woff") format("woff");
}

@font-face {
  font-family: ABBvoice;
  font-weight: 500;
  src:
    url("/assets/fonts/ABBvoice_W_Md.eot") format("eot"),
    url("/assets/fonts/ABBvoice_W_Md.woff2") format("woff2"),
    url("/assets/fonts/ABBvoice_W_Md.woff") format("woff");
}

@font-face {
  font-family: ABBvoice;
  font-weight: 700;
  src:
    url("/assets/fonts/ABBvoice_W_Bd.eot") format("eot"),
    url("/assets/fonts/ABBvoice_W_Bd.woff2") format("woff2"),
    url("/assets/fonts/ABBvoice_W_Bd.woff") format("woff");
}
