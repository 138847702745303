@use "../base/mixins";

.mat-mdc-tab-labels {
  margin: var(--procure-vertical-spacing) var(--procure-horizontal-spacing) 0;
  padding: var(--procure-vertical-spacing) var(--procure-horizontal-spacing) 0;
  border: 1px solid var(--procure-color-grey);
  border-bottom: 0;
  border-radius: var(--procure-border-radius) var(--procure-border-radius) 0 0;
  background-color: var(--procure-color-light-grey);
  gap: 0.75rem;

  .mat-mdc-tab {
    border: 1px solid var(--procure-color-grey);
    border-bottom: 0;
    border-radius: var(--procure-border-radius) var(--procure-border-radius) 0 0;

    &:hover,
    &:focus {
      .mdc-tab__text-label {
        color: var(--procure-color-black);
      }
    }

    &.mdc-tab {
      height: auto;
      padding: 0.75rem;
      background-color: var(--procure-color-light-grey);
    }

    &.mdc-tab--active {
      background-color: var(--procure-brand-grey);

      .mdc-tab__text-label {
        color: var(--procure-color-white);
      }

      &:hover,
      &:focus {
        /* stylelint-disable-next-line max-nesting-depth, selector-max-specificity */
        .mdc-tab__text-label {
          color: var(--procure-color-white);
        }
      }
    }

    .mdc-tab__text-label {
      transition: none;
      color: var(--procure-color-black);
      font-weight: 500;
      letter-spacing: normal;
    }

    .mat-ripple,
    .mdc-tab__ripple {
      display: none;
    }

    .mdc-tab-indicator {
      display: none;
    }
  }
}

.mat-mdc-tab-body-wrapper {
  margin: 0 var(--procure-horizontal-spacing) var(--procure-vertical-spacing);
  border: 1px solid var(--procure-color-grey);
  border-radius: 0 0 var(--procure-border-radius) var(--procure-border-radius);
}
