// stylelint-disable selector-no-qualifying-type, no-duplicate-selectors

.mat-mdc-table {
  &.mdc-data-table__table {
    background-color: transparent;
  }

  td.mat-mdc-cell,
  td.mat-mdc-footer-cell,
  th.mat-mdc-header-cell {
    padding: calc(var(--procure-vertical-spacing) * 0.5)
      calc(var(--procure-horizontal-spacing) * 0.5);

    &:first-child,
    &:first-child,
    &:first-child {
      padding-left: var(--procure-horizontal-spacing);
    }

    &:last-child,
    &:last-child,
    &:last-child {
      padding-right: var(--procure-horizontal-spacing);
    }
  }

  th.mat-mdc-header-cell {
    color: var(--procure-brand-grey);
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 0.05em;

    .mat-sort-header-container {
      letter-spacing: 0.05em;
    }
  }

  .mat-sort-header-button {
    text-align: left;
  }

  .mat-mdc-header-cell {
    white-space: nowrap;
  }

  .mat-mdc-row:nth-child(odd) {
    background-color: var(--procure-table-zebra-background);

    &:hover,
    &:focus {
      background-color: var(--procure-table-zebra-background);
    }
  }
}

.table-button-column {
  width: 1px;
}

.mat-mdc-no-data-row td {
  padding: var(--procure-vertical-spacing) var(--procure-horizontal-spacing);
  color: var(--procure-brand-grey);
  font-size: 1rem;
}

.state--selected {
  &,
  input {
    box-shadow: inset 0 0 0 1px var(--procure-brand-success);
  }
}

// stylelint-enable selector-no-qualifying-type, no-duplicate-selectors
