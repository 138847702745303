$font-family-sans-serif: "ABBvoice", "Arial", sans-serif;
$font-family-monospace: "Menlo", "Monaco", "Consolas", "Courier New", monospace;
$primary-font: $font-family-sans-serif;

//  ==========================================
//  Layout settings
//  ==========================================

// Breakpoints
$screen-sm-min: 48rem;
$screen-md-min: 62rem;
$screen-lg-min: 75rem;
$screen-xl-min: 87rem;
$screen-xxl-min: 98rem;
$screen-xxxl-min: 120rem;


$breakpoints: (
  s: $screen-sm-min,
  m: $screen-md-min,
  l: $screen-lg-min,
  xl: $screen-xl-min,
  xxl: $screen-xxl-min,
  xxxl: $screen-xxxl-min,
);
