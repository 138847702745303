//  This file is for setting global styles
@use "base/variables";

// Override variables
@use "base/typography";

// Components
@use "component/angular";
@use "component/forms";
@use "component/tables";
@use "component/input";
@use "component/form-attachments";
@use "component/links";
@use "component/material";
@use "component/menu";
@use "component/ng-select";
@use "component/ckeditor";
@use "component/stepper";
@use "component/dialog";
@use "component/tabs";
@use "component/tooltips";
@use "component/shepherd" as shepherdOverrides;

// Other
@use "other/backdrop";
@use "other/errors";
@use "other/forms" as otherForms;
@use "other/text";

// Material
@use "@angular/material" as mat;
@use "material-symbols";

// Icons
@use "material-icons/iconfont/material-icons.css";

// Libraries
@use "shepherd.js/dist/css/shepherd.css";

// Normalize styles across browsers
@use "normalize.css/normalize.css";
@use "./base/m3-theme";

// Setup Material styles
@include mat.core;

//  Include theme styles for core and each component used in your app.
//  Alternatively, you can import and @include the theme mixins for each component
//  that you are using.
html {
  // Apply the light theme by default
  @include mat.core-theme(m3-theme.$light-theme);
  @include mat.all-component-themes(m3-theme.$light-theme);
}

:root {
  // Spacings
  --procure-vertical-spacing: 0.75rem;
  --procure-horizontal-spacing: 0.75rem;
  --procure-sidenav-width: 4.5rem;
  --procure-main-top-offset: 3.25rem;

  // Brand colors
  --procure-brand-accent: #ff000f;
  --procure-brand-info: #004c97;
  --procure-brand-success: #007a33;
  --procure-brand-warning: #ffd100;
  --procure-brand-error: #e86a70;
  --procure-brand-grey: #737373;

  // Entity colors
  --procure-entity-projects: #ff6c25;
  --procure-entity-rfq: #0096ea;
  --procure-entity-rfrfq: #00acb6;
  --procure-entity-preq: #eece23;
  --procure-entity-quotations: #6d1541;
  --procure-entity-category: #000000;
  --procure-entity-trfrfq: #00667a;
  --procure-entity-spt: #190482;
  --procure-entity-spe: #6d67e4;

  // Utility colors
  --procure-color-white: #ffffff;
  --procure-color-light-grey: #eaeaea;
  --procure-color-grey: #d9d9d9;
  --procure-color-medium-grey: #adadad;
  --procure-color-dark-grey: #2d2d2d;
  --procure-color-black: #0f0f0f;
  --procure-color-red: #ff0000;

  // Layer colors
  --procure-card-background-color: #ffffff;

  // Selection
  --procure-selection-color: #eb0023;

  // Status colors
  --procure-status-grey: #868686;
  --procure-status-blue: #4782ff;
  --procure-status-green: #00b102;
  --procure-status-yellow: #eece23;
  --procure-status-orange: #ff6c25;
  --procure-status-red: #ff4947;
  --procure-status-white: #ffffff;
  --procure-status-yellow-light: #ffef99;

  // Borders
  --procure-border-color: var(--procure-color-light-grey);

  // Radius
  --procure-border-radius: 3px;
  --procure-dialog-border-radius: 3px;

  // Shadows
  --procure-box-shadow: 0 1px 3px rgb(15 15 15 / 10%);
  --procure-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0 0 0 / 20%),
    0px 4px 5px 0px rgba(0 0 0 / 14%), 0px 1px 10px 0px rgba(0 0 0 / 12%);
  --procure-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0 0 0 / 20%),
    0px 24px 38px 3px rgba(0 0 0 / 14%), 0px 9px 46px 8px rgba(0 0 0 / 12%);

  --procure-box-shadow-table-row: 0 5px 5px -3px rgba(0 0 0 / 20%), 0 8px 10px 1px rgba(0 0 0 / 14%),
    0 3px 14px 2px rgba(0 0 0 / 12%);

  // Controls
  --procure-control-background-color: color-mix(
    in sRGB,
    var(--procure-brand-warning),
    transparent 95%
  );
  --procure-control-prefilled: #1975d1;
  --procure-switch-selected-focus-track: color-mix(
    in sRGB,
    var(--procure-status-green) 10%,
    var(--procure-color-light-grey)
  );

  // Newsfeed
  --procure-newsfeed-poll-color: #0f8286;
  --procure-newsfeed-question-color: #0e76d1;
  --procure-newsfeed-discussion-color: #f59c3f;
  --procure-newsfeed-tag-color: #cff1fc;
  --procure-newsfeed-post-body-width: 43rem;

  // Typography
  --procure-label-font-weight: 400;

  // Layers
  --procure-layer-navigation-fixed: 950;
  --procure-layer-quick-search-panel: 1000;
  --procure-layer-navbar: 1000;
  --procure-layer-overlay: 1100;

  // Tooltips
  --procure-tooltip-background: color-mix(in sRGB, var(--procure-color-black) 90%, transparent);

  // Tables
  --procure-table-zebra-background: color-mix(
    in sRGB,
    var(--procure-color-light-grey) 20%,
    transparent
  );
  --procure-table-border-color: rgba(0 0 0 / 12%);

  // Transitions
  --procure-drag-and-drop-transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

  // CKEditor
  /* stylelint-disable-next-line declaration-no-important */
  --ck-color-base-background: var(--procure-control-background-color) !important;

  // Material 3

  // Select
  --mat-option-selected-state-layer-color: var(--procure-color-light-grey);
  --mat-select-panel-background-color: var(--procure-color-white);
  --mat-select-focused-arrow-color: var(--procure-brand-grey);

  // Menu
  --mat-menu-container-color: var(--procure-card-background-color);
  --mat-menu-item-label-text-weight: 400;
  --mat-menu-item-label-text-size: 1rem;

  // Form Fields
  --mdc-outlined-text-field-container-shape: var(--procure-border-radius);
  --mdc-outlined-text-field-outline-width: 1px;
  --mat-form-field-container-height: 3.25rem;
  --mdc-filled-text-field-container-color: var(--procure-color-light-grey);
  --mdc-filled-text-field-focus-active-indicator-color: var(--procure-brand-grey);
  --mdc-outlined-text-field-focus-label-text-color: var(--procure-brand-grey);
  --mat-form-field-container-vertical-padding: 1rem;

  // Expansion Panel
  --mat-expansion-container-background-color: var(--procure-card-background-color);
  --mat-expansion-header-text-size: 0.875rem;
  --mat-expansion-header-indicator-color: var(--procure-brand-accent);

  // Snackbar
  --mdc-snackbar-supporting-text-color: var(--procure-color-white);

  // Autocomplete
  --mat-autocomplete-background-color: var(--procure-color-white);

  // Chip
  --mdc-chip-container-shape-radius: 1px;

  // Radio
  --mdc-radio-selected-focus-icon-color: var(--procure-selection-color);
  --mdc-radio-selected-hover-icon-color: var(--procure-selection-color);
  --mdc-radio-selected-icon-color: var(--procure-selection-color);
  --mdc-radio-selected-pressed-icon-color: var(--procure-selection-color);
  --mat-radio-checked-ripple-color: var(--procure-selection-color);

  // List
  --mdc-list-list-item-selected-trailing-icon-color: var(--procure-brand-grey);

  // Tabs
  --mat-tab-header-active-focus-indicator-color: var(--procure-brand-grey);
  --mat-tab-header-active-hover-indicator-color: var(--procure-brand-grey);

  // Badge
  --mat-badge-background-color: var(--procure-brand-grey);

  // Button
  --mat-standard-button-toggle-shape: var(--procure-border-radius);
  --mat-standard-button-toggle-divider-color: var(--procure-color-grey);
  --mat-standard-button-toggle-selected-state-background-color: var(--procure-color-light-grey);
  --mdc-icon-button-state-layer-size: 2.5rem;
  --mat-text-button-state-layer-color: var(--procure-brand-grey);
  --mdc-protected-button-label-text-color: var(--procure-brand-grey);
  --mdc-text-button-label-text-color: var(--procure-brand-grey);
  --mat-standard-button-toggle-label-text-line-height: 3.25rem;
  --mdc-filled-button-container-color: var(--procure-brand-grey);

  // Checkbox
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--procure-brand-grey);
  --mdc-checkbox-selected-icon-color: var(--procure-selection-color);
  --mdc-checkbox-selected-focus-icon-color: var(--procure-selection-color);
  --mdc-checkbox-selected-hover-icon-color: var(--procure-selection-color);
  --mdc-checkbox-selected-focus-state-layer-color: var(--procure-selection-color);
  --mdc-checkbox-selected-hover-state-layer-color: var(--procure-selection-color);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--procure-selection-color);

  // Dialog
  --mdc-dialog-container-color: var(--procure-card-background-color);
  --mat-dialog-container-max-width: 100%;
  --mat-dialog-container-elevation-shadow: var(--procure-dialog-container-elevation-shadow);
  --mdc-dialog-container-shape: var(--procure-border-radius);

  // Tooltip
  --mdc-plain-tooltip-supporting-text-color: var(--procure-color-white);

  // Slider
  --mdc-slider-inactive-track-color: var(--procure-color-light-grey);
  --mat-slider-ripple-color: var(--procure-brand-grey);
  --mat-slider-value-indicator-border-radius: 50% 0 50% 50%;
  --mdc-slider-label-label-text-size: 0.5rem;
  --mdc-slider-label-label-text-tracking: 0;
  --mdc-slider-handle-color: var(--procure-brand-grey);
  --mdc-slider-focus-handle-color: var(--procure-brand-grey);
  --mdc-slider-hover-handle-color: var(--procure-brand-grey);
  --mdc-slider-active-track-color: var(--procure-brand-grey);
  --mdc-slider-label-container-color: var(--procure-brand-grey);

  // Stepper
  --mat-stepper-container-color: var(--procure-color-white);
  --mat-stepper-header-selected-state-icon-background-color: var(--procure-selection-color);
  --mat-stepper-header-edit-state-icon-background-color: var(--procure-selection-color);
  --mat-stepper-header-focus-state-layer-shape: 1px;
  --mat-stepper-header-hover-state-layer-shape: 1px;

  // Table
  --mat-table-row-item-outline-color: var(--procure-border-color);
  --mat-table-row-item-container-height: 3.25rem;

  // Progress Bar
  --mdc-linear-progress-track-color: color-mix(in sRGB, var(--procure-brand-info), transparent 60%);
  --mdc-linear-progress-active-indicator-color: var(--procure-brand-info);

  // Datepicker
  --mat-datepicker-calendar-container-background-color: var(--procure-color-white);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--procure-brand-grey);
  --mat-datepicker-calendar-date-today-outline-color: var(--procure-brand-grey);
  --mat-datepicker-calendar-text-size: 0.875rem;
  --mat-datepicker-calendar-navigation-button-icon-color: var(--procure-brand-grey);
  --mat-datepicker-calendar-container-elevation-shadow: var(
    --procure-datepicker-calendar-container-elevation-shadow
  );
  --mat-datepicker-calendar-container-shape: 0.25rem;
  --mat-datepicker-calendar-container-touch-shape: 0.25rem;
  --mat-datepicker-calendar-container-text-color: var(--procure-color-medium-grey);
  --mat-datepicker-calendar-header-text-size: 0.75rem;
  --mat-datepicker-calendar-header-text-weight: 400;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: var(--procure-color-grey);
  --mat-text-button-ripple-color: var(--procure-color-grey);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--procure-selection-color);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--procure-selection-color);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--procure-color-grey);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(
    --procure-color-grey
  );

  // Switch
  --mdc-switch-selected-handle-color: var(--procure-status-green);
  --mdc-switch-selected-focus-state-layer-color: var(--procure-status-green);
  --mdc-switch-selected-hover-state-layer-color: var(--procure-status-green);
  --mdc-switch-selected-pressed-state-layer-color: var(--procure-status-green);
  --mdc-switch-selected-focus-handle-color: var(--procure-status-green);
  --mdc-switch-selected-hover-handle-color: var(--procure-status-green);
  --mdc-switch-selected-pressed-handle-color: var(--procure-status-green);
  --mdc-switch-disabled-selected-handle-color: var(--procure-status-green);
  --mdc-switch-disabled-selected-track-color: var(--procure-status-green);

  --mdc-switch-selected-focus-track-color: var(--procure-switch-selected-focus-track);
  --mdc-switch-selected-hover-track-color: var(--procure-switch-selected-focus-track);
  --mdc-switch-selected-pressed-track-color: var(--procure-switch-selected-focus-track);
  --mdc-switch-selected-track-color: var(--procure-switch-selected-focus-track);

  --mdc-switch-unselected-focus-handle-color: var(--procure-status-grey);
  --mdc-switch-unselected-focus-state-layer-color: var(--procure-color-grey);
  --mdc-switch-unselected-focus-track-color: var(--procure-color-grey);
  --mdc-switch-unselected-handle-color: var(--procure-color-black);
  --mdc-switch-unselected-hover-handle-color: var(--procure-color-grey);
  --mdc-switch-unselected-hover-state-layer-color: var(--procure-color-grey);

  --mdc-switch-unselected-hover-track-color: var(--procure-light-grey);
  --mdc-switch-unselected-icon-color: var(--procure-color-grey);
  --mdc-switch-unselected-pressed-handle-color: var(--procure-status-grey);
  --mdc-switch-unselected-pressed-state-layer-color: var(--procure-color-black);
  --mdc-switch-unselected-pressed-track-color: var(--procure-light-grey);
  --mdc-switch-unselected-track-color: var(--procure-light-grey);

  --mdc-switch-track-height: 1.5rem;
  --mat-switch-with-icon-handle-size: 1rem;
  --mat-switch-pressed-handle-size: 1rem;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 2rem;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 4px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 2rem;
}

body {
  position: relative;
  font-family: variables.$primary-font;
}

[hidden] {
  // stylelint-disable-next-line declaration-no-important
  display: none !important;
}

pdf-zoom-dropdown {
  display: none;
}

.ck.ck-balloon-panel {
  --ck-color-panel-background: var(--procure-color-white);
}

.ck.ck-list__item .ck-button.ck-on {
  --ck-color-list-button-on-text: var(--procure-color-white);
}

.ck.ck-list {
  --ck-color-list-background: var(--procure-color-white);
}
