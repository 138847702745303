.mat-mdc-menu-item[disabled] .mat-mdc-menu-item-text {
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.inbox__panel {
  width: 35rem;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: calc(2 * var(--procure-vertical-spacing));
}

.inbox__backdrop + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  max-height: calc(100vh - 3rem);
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  width: 100%;
}
