// stylelint-disable declaration-no-important

.mdc-tooltip .mdc-tooltip__surface {
  max-width: 30em !important;
  padding: 0.25rem var(--procure-horizontal-spacing) !important;
  background-color: var(--procure-tooltip-background) !important;
  font-size: 0.85rem !important;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.35 !important;
}

.line-breaks-tooltip {
  white-space: pre-line;
}

// stylelint-enable declaration-no-important
