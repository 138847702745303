@use "../base/mixins";

.form-row {
  $root: &;

  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  margin-right: calc(var(--procure-horizontal-spacing) * -1);
  margin-left: calc(var(--procure-horizontal-spacing) * -1);
  padding-right: var(--procure-horizontal-spacing);
  padding-left: var(--procure-horizontal-spacing);
  gap: var(--procure-horizontal-spacing);

  /* stylelint-disable-next-line selector-max-universal */
  > * {
    flex-grow: 1;
    width: 0;
  }

  &:not(:last-child),
  #{$root}--standalone {
    margin-bottom: var(--procure-vertical-spacing);
    border-bottom: 1px solid var(--procure-border-color);
  }

  > :first-child:not(:last-child) {
    @include mixins.up-to(m) {
      margin: 0 calc(var(--procure-vertical-spacing) * -1);
      padding: 0 var(--procure-horizontal-spacing);
      border-bottom: 1px solid var(--procure-border-color);
    }
  }

  @include mixins.up-to(m) {
    flex-direction: column;

    /* stylelint-disable-next-line selector-max-universal */
    > * {
      width: 100%;
    }
  }
}
